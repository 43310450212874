/* Votre Style */


html{
  height:100%;
}

.global{
  min-height: 100%;
  position: relative;
}


ul {
  background: #bbb;
}
li, [class*="grid"] > * {
  background: #ccc;
}

header {
  background-color: #4066ff;
  min-height: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
main {
  background: #eee;
}
aside {
  align-items: center;
  float: right;
  width: 25%;
  background: #ffffff;
}

article {
  float: left;
  width: 65%;
  margin-right: 1%;
  background: #ffffff;
}

footer {
	
   background: #4066ff;
   position: absolute;
   bottom: 0;
   width: 100%;
   height: 50px;
   margin-top: 50px;   
}

.global{
  min-height:100%;
  position: relative;
}