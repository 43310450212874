@charset "UTF-8";
/*!
* www.KNACSS.com v7.1.1 (december, 10 2018) @author: Alsacreations, Raphael Goetter
* Licence WTFPL http://www.wtfpl.net/
*/
/* ----------------------------- */
/* ==Table Of Content            */
/* ----------------------------- */
/*
1- Reboot (basic reset)
2- Libraries  :
  - Base
  - Print
  - Layout (alignment, modules, positionning)
  - Utilities (width and spacers helpers)
  - Responsive helpers
  - WordPress reset (disabled by default)
  - Grillade (Grid System)
3- Components :
  - Media object
  - Skip Links for accessibility
  - Tables
  - Forms
  - Buttons
  - Checkbox
  - Tabs
  - Tags
  - Badges
  - Alerts
*/
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
  overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bold;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
      touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
     -moz-appearance: button;
          appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
     -moz-appearance: listbox;
          appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
          appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
          appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/* ----------------------------- */
/* ==Base (basic styles)         */
/* ----------------------------- */
/* disable animations styles when reduced rotion is enabled */
@media (prefers-reduced-motion: reduce) {
  * {
    -webkit-animation: none !important;
            animation: none !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
}

/* switching to border-box model for all elements */
html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
  /* avoid min-width: auto on flex and grid children */
  min-width: 0;
  min-height: 0;
}

html {
  /* set base font-size to equiv "10px", which is adapted to rem unit */
  font-size: 62.5%;
  /* IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
  font-size: calc(1em * 0.625);
}

body {
  margin: 0;
  font-size: 1.4rem;
  background-color: #fff;
  color: #212529;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  line-height: 1.5;
}

@media (min-width: 576px) {
  body {
    font-size: 1.6rem;
  }
}

/* Links */
a {
  color: #333;
  text-decoration: underline;
}

a:focus, a:hover, a:active {
  color: #0d0d0d;
  text-decoration: underline;
}

/* Headings */
h1, .h1-like {
  font-size: 2.8rem;
  font-family: sans-serif;
  font-weight: 500;
}

@media (min-width: 576px) {
  h1, .h1-like {
    font-size: 3.2rem;
  }
}

h2, .h2-like {
  font-size: 2.4rem;
  font-family: sans-serif;
  font-weight: 500;
}

@media (min-width: 576px) {
  h2, .h2-like {
    font-size: 2.8rem;
  }
}

h3, .h3-like {
  font-size: 2rem;
  font-weight: 500;
}

@media (min-width: 576px) {
  h3, .h3-like {
    font-size: 2.4rem;
  }
}

h4, .h4-like {
  font-size: 1.8rem;
  font-weight: 500;
}

@media (min-width: 576px) {
  h4, .h4-like {
    font-size: 2rem;
  }
}

h5, .h5-like {
  font-size: 1.6rem;
  font-weight: 500;
}

@media (min-width: 576px) {
  h5, .h5-like {
    font-size: 1.8rem;
  }
}

h6, .h6-like {
  font-size: 1.4rem;
  font-weight: 500;
}

@media (min-width: 576px) {
  h6, .h6-like {
    font-size: 1.6rem;
  }
}

/* Vertical rythm */
h1,
h2,
h3,
h4,
h5,
h6,
dd {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p,
address,
ol,
ul,
dl,
blockquote,
pre {
  margin-top: 0;
  margin-bottom: 1rem;
}

/* Avoid margins on nested elements */
li p,
li .p-like,
li ul,
li ol,
ol ol,
ul ul {
  margin-top: 0;
  margin-bottom: 0;
}

/* Max values */
img,
table,
td,
blockquote,
code,
pre,
textarea,
input,
video,
svg {
  max-width: 100%;
}

img {
  height: auto;
}

/* Styling elements */
ul,
ol {
  padding-left: 2em;
}

img {
  vertical-align: middle;
}

em,
.italic,
address,
cite,
i,
var {
  font-style: italic;
}

code,
kbd,
mark {
  border-radius: 2px;
}

kbd {
  padding: 0 2px;
  border: 1px solid #999;
}

pre {
  -moz-tab-size: 2;
    -o-tab-size: 2;
       tab-size: 2;
}

code {
  padding: 2px 4px;
  background: rgba(0, 0, 0, 0.04);
  color: #b11;
}

pre code {
  padding: 0;
  background: none;
  color: inherit;
  border-radius: 0;
}

mark {
  padding: 2px 4px;
}

sup,
sub {
  vertical-align: 0;
}

sup {
  bottom: 1ex;
}

sub {
  top: 0.5ex;
}

blockquote {
  position: relative;
  padding-left: 3em;
  min-height: 2em;
}

blockquote::before {
  content: "\201C";
  position: absolute;
  left: 0;
  top: 0;
  font-family: georgia, serif;
  font-size: 5em;
  height: .4em;
  line-height: .9;
  color: #e7e9ed;
}

blockquote > footer {
  margin-top: .75em;
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.7);
}

blockquote > footer::before {
  content: "\2014 \0020";
}

q {
  font-style: normal;
}

q,
.q {
  quotes: "“" "”" "‘" "’";
}

q:lang(fr),
.q:lang(fr) {
  quotes: "«\00a0" "\00a0»" "“" "”";
}

hr {
  display: block;
  clear: both;
  height: 1px;
  margin: 1em 0 2em;
  padding: 0;
  border: 0;
  color: #ccc;
  background-color: #ccc;
}

blockquote,
figure {
  margin-left: 0;
  margin-right: 0;
}

code,
pre,
samp,
kbd {
  white-space: pre-wrap;
  font-family: consolas, courier, monospace;
  line-height: normal;
}

/* ----------------------------- */
/* ==Print (quick print reset)   */
/* ----------------------------- */
@media print {
  * {
    background: transparent !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    text-shadow: none !important;
  }
  body {
    width: auto;
    margin: auto;
    font-family: serif;
    font-size: 12pt;
  }
  p,
  .p-like,
  h1,
  .h1-like,
  h2,
  .h2-like,
  h3,
  .h3-like,
  h4,
  .h4-like,
  h5,
  .h5-like,
  h6,
  .h6-like,
  blockquote,
  label,
  ul,
  ol {
    color: #000;
    margin: auto;
  }
  .print {
    display: block;
  }
  .no-print {
    display: none;
  }
  /* no orphans, no widows */
  p,
  .p-like,
  blockquote {
    orphans: 3;
    widows: 3;
  }
  /* no breaks inside these elements */
  blockquote,
  ul,
  ol {
    page-break-inside: avoid;
  }
  /* page break before main headers
  h1,
  .h1-like {
    page-break-before: always;
  }
  */
  /* no breaks after these elements */
  h1,
  .h1-like,
  h2,
  .h2-like,
  h3,
  .h3-like,
  caption {
    page-break-after: avoid;
  }
  a {
    color: #000;
  }
  /* displaying URLs
  a[href]::after {
    content: " (" attr(href) ")";
  }
  */
  a[href^="javascript:"]::after,
  a[href^="#"]::after {
    content: "";
  }
}

/* ----------------------------- */
/* ==Global Micro Layout         */
/* ----------------------------- */
/* Flexbox layout is KNACSS choice
http://www.alsacreations.com/tuto/lire/1493-css3-flexbox-layout-module.html
*/
.flex-container, .flex-container--row,
.flex-row, .flex-container--column,
.flex-column, .flex-container--row-reverse,
.flex-row-reverse, .flex-container--column-reverse,
.flex-column-reverse,
.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.flex-container--row,
.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.flex-container--column,
.flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.flex-container--row-reverse,
.flex-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.flex-container--column-reverse,
.flex-column-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.flex-item-fluid,
.item-fluid {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0%;
          flex: 1 1;
}

.flex-item-first,
.item-first {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}

.flex-item-medium,
.item-medium {
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0;
}

.flex-item-last,
.item-last {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}

.flex-item-center,
.item-center,
.mr-auto {
  margin: auto;
}

/* ---------------------------------- */
/* ==Helpers                          */
/* ---------------------------------- */
/* Typo Helpers  */
/* ------------- */
.u-bold {
  font-weight: 700;
}

.u-italic {
  font-style: italic;
}

.u-normal {
  font-weight: normal;
  font-style: normal;
}

.u-uppercase {
  text-transform: uppercase;
}

.u-lowercase {
  text-transform: lowercase;
}

.u-smaller {
  font-size: 0.6em;
}

.u-small {
  font-size: 0.8em;
}

.u-big {
  font-size: 1.2em;
}

.u-bigger {
  font-size: 1.5em;
}

.u-biggest {
  font-size: 2em;
}

.u-txt-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

.u-txt-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* text and contents alignment */
.txtleft,
.u-txt-left {
  text-align: left;
}

.txtright,
.u-txt-right {
  text-align: right;
}

.txtcenter,
.u-txt-center {
  text-align: center;
}

/* blocks that need to be placed under floats */
.clear,
.u-clear {
  clear: both;
}

/* blocks that must contain floats */
.clearfix::after,
.u-clearfix::after {
  content: "";
  display: table;
  clear: both;
  border-collapse: collapse;
}

/* simple blocks alignment */
.left .u-left {
  margin-right: auto;
}

.right,
.u-right {
  margin-left: auto;
}

.center,
.u-center {
  margin-left: auto;
  margin-right: auto;
}

/* Global Micro Layout */
/* ------------------- */
/* module, gains superpower "BFC" Block Formating Context */
.mod,
.u-mod,
.bfc,
.u-bfc {
  overflow: hidden;
}

/* floating elements */
.fl,
.u-fl {
  float: left;
}

img.fl,
img.u-fl {
  margin-right: 1rem;
}

.fr,
.u-fr {
  float: right;
}

img.fr,
img.u-fr {
  margin-left: 1rem;
}

img.fl,
img.fr,
img.u-fl,
img.u-fr {
  margin-bottom: 0.5rem;
}

/* inline-block */
.inbl,
.u-inbl {
  display: inline-block;
  vertical-align: top;
}

/* State Helpers */
/* ------------- */
/* invisible for all */
.is-hidden,
.js-hidden,
[hidden] {
  display: none;
}

/* hidden but not for an assistive technology like a screen reader, Yahoo! method */
.visually-hidden, .tabs-content-item[aria-hidden="true"] {
  position: absolute !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
}

.is-disabled,
.js-disabled,
[disabled],
.is-disabled ~ label,
[disabled] ~ label {
  opacity: 0.5;
  cursor: not-allowed !important;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}

ul.is-unstyled, ul.unstyled {
  list-style: none;
  padding-left: 0;
}

.color--inverse {
  color: #fff;
}

/* Width Helpers */
/* ------------- */
/* blocks widths (percentage and pixels) */
.w100 {
  width: 100%;
}

.w95 {
  width: 95%;
}

.w90 {
  width: 90%;
}

.w85 {
  width: 85%;
}

.w80 {
  width: 80%;
}

.w75 {
  width: 75%;
}

.w70 {
  width: 70%;
}

.w65 {
  width: 65%;
}

.w60 {
  width: 60%;
}

.w55 {
  width: 55%;
}

.w50 {
  width: 50%;
}

.w45 {
  width: 45%;
}

.w40 {
  width: 40%;
}

.w35 {
  width: 35%;
}

.w30 {
  width: 30%;
}

.w25 {
  width: 25%;
}

.w20 {
  width: 20%;
}

.w15 {
  width: 15%;
}

.w10 {
  width: 10%;
}

.w5 {
  width: 5%;
}

.w66 {
  width: calc(100% / 3 * 2);
}

.w33 {
  width: calc(100% / 3);
}

.wauto {
  width: auto;
}

.w960p {
  width: 960px;
}

.mw960p {
  max-width: 960px;
}

.w1140p {
  width: 1140px;
}

.mw1140p {
  max-width: 1140px;
}

.w1000p {
  width: 1000px;
}

.w950p {
  width: 950px;
}

.w900p {
  width: 900px;
}

.w850p {
  width: 850px;
}

.w800p {
  width: 800px;
}

.w750p {
  width: 750px;
}

.w700p {
  width: 700px;
}

.w650p {
  width: 650px;
}

.w600p {
  width: 600px;
}

.w550p {
  width: 550px;
}

.w500p {
  width: 500px;
}

.w450p {
  width: 450px;
}

.w400p {
  width: 400px;
}

.w350p {
  width: 350px;
}

.w300p {
  width: 300px;
}

.w250p {
  width: 250px;
}

.w200p {
  width: 200px;
}

.w150p {
  width: 150px;
}

.w100p {
  width: 100px;
}

.w50p {
  width: 50px;
}

/* Spacing Helpers */
/* --------------- */
.man,
.ma0 {
  margin: 0;
}

.pan,
.pa0 {
  padding: 0;
}

.mas {
  margin: 1rem;
}

.mam {
  margin: 2rem;
}

.mal {
  margin: 4rem;
}

.pas {
  padding: 1rem;
}

.pam {
  padding: 2rem;
}

.pal {
  padding: 4rem;
}

.mtn,
.mt0 {
  margin-top: 0;
}

.mts {
  margin-top: 1rem;
}

.mtm {
  margin-top: 2rem;
}

.mtl {
  margin-top: 4rem;
}

.mrn,
.mr0 {
  margin-right: 0;
}

.mrs {
  margin-right: 1rem;
}

.mrm {
  margin-right: 2rem;
}

.mrl {
  margin-right: 4rem;
}

.mbn,
.mb0 {
  margin-bottom: 0;
}

.mbs {
  margin-bottom: 1rem;
}

.mbm {
  margin-bottom: 2rem;
}

.mbl {
  margin-bottom: 4rem;
}

.mln,
.ml0 {
  margin-left: 0;
}

.mls {
  margin-left: 1rem;
}

.mlm {
  margin-left: 2rem;
}

.mll {
  margin-left: 4rem;
}

.mauto {
  margin: auto;
}

.mtauto {
  margin-top: auto;
}

.mrauto {
  margin-right: auto;
}

.mbauto {
  margin-bottom: auto;
}

.mlauto {
  margin-left: auto;
}

.ptn,
.pt0 {
  padding-top: 0;
}

.pts {
  padding-top: 1rem;
}

.ptm {
  padding-top: 2rem;
}

.ptl {
  padding-top: 4rem;
}

.prn,
.pr0 {
  padding-right: 0;
}

.prs {
  padding-right: 1rem;
}

.prm {
  padding-right: 2rem;
}

.prl {
  padding-right: 4rem;
}

.pbn,
.pb0 {
  padding-bottom: 0;
}

.pbs {
  padding-bottom: 1rem;
}

.pbm {
  padding-bottom: 2rem;
}

.pbl {
  padding-bottom: 4rem;
}

.pln,
.pl0 {
  padding-left: 0;
}

.pls {
  padding-left: 1rem;
}

.plm {
  padding-left: 2rem;
}

.pll {
  padding-left: 4rem;
}

/* -------------------------- */
/* ==Responsive helpers       */
/* -------------------------- */
/* large screens */
/* ------------- */
@media (min-width: 992px) {
  /* layouts for large screens */
  .large-hidden {
    display: none !important;
  }
  .large-visible {
    display: block !important;
  }
  .large-no-float {
    float: none;
  }
  .large-inbl {
    display: inline-block;
    float: none;
    vertical-align: top;
  }
  /* widths for large screens */
  .large-w25 {
    width: 25% !important;
  }
  .large-w33 {
    width: 33.333333% !important;
  }
  .large-w50 {
    width: 50% !important;
  }
  .large-w66 {
    width: 66.666666% !important;
  }
  .large-w75 {
    width: 75% !important;
  }
  .large-w100,
  .large-wauto {
    display: block !important;
    float: none !important;
    clear: none !important;
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 0;
  }
  /* margins for large screens */
  .large-man,
  .large-ma0 {
    margin: 0 !important;
  }
}

/* medium screens */
/* -------------- */
@media (min-width: 768px) and (max-width: 991px) {
  /* layouts for medium screens */
  .medium-hidden {
    display: none !important;
  }
  .medium-visible {
    display: block !important;
  }
  .medium-no-float {
    float: none;
  }
  .medium-inbl {
    display: inline-block;
    float: none;
    vertical-align: top;
  }
  /* widths for medium screens */
  .medium-w25 {
    width: 25% !important;
  }
  .medium-w33 {
    width: 33.333333% !important;
  }
  .medium-w50 {
    width: 50% !important;
  }
  .medium-w66 {
    width: 66.666666% !important;
  }
  .medium-w75 {
    width: 75% !important;
  }
  .medium-w100,
  .medium-wauto {
    display: block !important;
    float: none !important;
    clear: none !important;
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 0;
  }
  /* margins for medium screens */
  .medium-man,
  .medium-ma0 {
    margin: 0 !important;
  }
}

/* small screens */
/* ------------- */
@media (min-width: 576px) and (max-width: 767px) {
  /* layouts for small screens */
  .small-hidden {
    display: none !important;
  }
  .small-visible {
    display: block !important;
  }
  .small-no-float {
    float: none;
  }
  .small-inbl {
    display: inline-block;
    float: none;
    vertical-align: top;
  }
  /* widths for small screens */
  .small-w25 {
    width: 25% !important;
  }
  .small-w33 {
    width: 33.333333% !important;
  }
  .small-w50 {
    width: 50% !important;
  }
  .small-w66 {
    width: 66.666666% !important;
  }
  .small-w75 {
    width: 75% !important;
  }
  .small-w100,
  .small-wauto {
    display: block !important;
    float: none !important;
    clear: none !important;
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 0;
  }
  /* margins for small screens */
  .small-man,
  .small-ma0 {
    margin: 0 !important;
  }
  .small-pan,
  .small-pa0 {
    padding: 0 !important;
  }
}

/* tiny screens */
/* ------------ */
@media (max-width: 575px) {
  /* quick small resolution reset */
  .mod,
  .col,
  fieldset {
    display: block !important;
    float: none !important;
    clear: none !important;
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 0;
  }
  .flex-container, .flex-container--row,
  .flex-row, .flex-container--column,
  .flex-column, .flex-container--row-reverse,
  .flex-row-reverse, .flex-container--column-reverse,
  .flex-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  /* layouts for tiny screens */
  .tiny-hidden {
    display: none !important;
  }
  .tiny-visible {
    display: block !important;
  }
  .tiny-no-float {
    float: none;
  }
  .tiny-inbl {
    display: inline-block;
    float: none;
    vertical-align: top;
  }
  /* widths for tiny screens */
  .tiny-w25 {
    width: 25% !important;
  }
  .tiny-w33 {
    width: 33.333333% !important;
  }
  .tiny-w50 {
    width: 50% !important;
  }
  .tiny-w66 {
    width: 66.666666% !important;
  }
  .tiny-w75 {
    width: 75% !important;
  }
  .tiny-w100,
  .tiny-wauto {
    display: block !important;
    float: none !important;
    clear: none !important;
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 0;
  }
  /* margins for tiny screens */
  .tiny-man,
  .tiny-ma0 {
    margin: 0 !important;
  }
  .tiny-pan,
  .tiny-pa0 {
    padding: 0 !important;
  }
}

/* --------------------------------------- */
/* ==Grillade : ultra light Grid System    */
/* --------------------------------------- */
@media (min-width: 480px) {
  [class*=" grid-"],
  [class^="grid-"] {
    display: -ms-grid;
    display: grid;
    grid-auto-flow: dense;
  }
  [class*=" grid-"].has-gutter,
  [class^="grid-"].has-gutter {
    grid-gap: 1rem;
  }
  [class*=" grid-"].has-gutter-l,
  [class^="grid-"].has-gutter-l {
    grid-gap: 2rem;
  }
  [class*=" grid-"].has-gutter-xl,
  [class^="grid-"].has-gutter-xl {
    grid-gap: 4rem;
  }
}

@media (min-width: 480px) {
  .autogrid,
  .grid {
    display: -ms-grid;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
  .autogrid.has-gutter,
  .grid.has-gutter {
    grid-column-gap: 1rem;
  }
  .autogrid.has-gutter-l,
  .grid.has-gutter-l {
    grid-column-gap: 2rem;
  }
  .autogrid.has-gutter-xl,
  .grid.has-gutter-xl {
    grid-column-gap: 4rem;
  }
}

[class*="grid-2"] {
  -ms-grid-columns: (1fr)[2];
  grid-template-columns: repeat(2, 1fr);
}

[class*="grid-3"] {
  -ms-grid-columns: (1fr)[3];
  grid-template-columns: repeat(3, 1fr);
}

[class*="grid-4"] {
  -ms-grid-columns: (1fr)[4];
  grid-template-columns: repeat(4, 1fr);
}

[class*="grid-5"] {
  -ms-grid-columns: (1fr)[5];
  grid-template-columns: repeat(5, 1fr);
}

[class*="grid-6"] {
  -ms-grid-columns: (1fr)[6];
  grid-template-columns: repeat(6, 1fr);
}

[class*="grid-7"] {
  -ms-grid-columns: (1fr)[7];
  grid-template-columns: repeat(7, 1fr);
}

[class*="grid-8"] {
  -ms-grid-columns: (1fr)[8];
  grid-template-columns: repeat(8, 1fr);
}

[class*="grid-9"] {
  -ms-grid-columns: (1fr)[9];
  grid-template-columns: repeat(9, 1fr);
}

[class*="grid-10"] {
  -ms-grid-columns: (1fr)[10];
  grid-template-columns: repeat(10, 1fr);
}

[class*="grid-11"] {
  -ms-grid-columns: (1fr)[11];
  grid-template-columns: repeat(11, 1fr);
}

[class*="grid-12"] {
  -ms-grid-columns: (1fr)[12];
  grid-template-columns: repeat(12, 1fr);
}

[class*="col-1"] {
  grid-column: auto/span 1;
}

[class*="row-1"] {
  grid-row: auto/span 1;
}

[class*="col-2"] {
  grid-column: auto/span 2;
}

[class*="row-2"] {
  grid-row: auto/span 2;
}

[class*="col-3"] {
  grid-column: auto/span 3;
}

[class*="row-3"] {
  grid-row: auto/span 3;
}

[class*="col-4"] {
  grid-column: auto/span 4;
}

[class*="row-4"] {
  grid-row: auto/span 4;
}

[class*="col-5"] {
  grid-column: auto/span 5;
}

[class*="row-5"] {
  grid-row: auto/span 5;
}

[class*="col-6"] {
  grid-column: auto/span 6;
}

[class*="row-6"] {
  grid-row: auto/span 6;
}

[class*="col-7"] {
  grid-column: auto/span 7;
}

[class*="row-7"] {
  grid-row: auto/span 7;
}

[class*="col-8"] {
  grid-column: auto/span 8;
}

[class*="row-8"] {
  grid-row: auto/span 8;
}

[class*="col-9"] {
  grid-column: auto/span 9;
}

[class*="row-9"] {
  grid-row: auto/span 9;
}

[class*="col-10"] {
  grid-column: auto/span 10;
}

[class*="row-10"] {
  grid-row: auto/span 10;
}

[class*="col-11"] {
  grid-column: auto/span 11;
}

[class*="row-11"] {
  grid-row: auto/span 11;
}

[class*="col-12"] {
  grid-column: auto/span 12;
}

[class*="row-12"] {
  grid-row: auto/span 12;
}

/* intermediate breakpoints */
@media (min-width: 480px) and (max-width: 767px) {
  [class*="grid-"][class*="-small-1"] {
    -ms-grid-columns: (1fr)[1];
    grid-template-columns: repeat(1, 1fr);
  }
  [class*="col-"][class*="-small-1"] {
    grid-column: auto/span 1;
  }
  [class*="grid-"][class*="-small-2"] {
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
  }
  [class*="col-"][class*="-small-2"] {
    grid-column: auto/span 2;
  }
  [class*="grid-"][class*="-small-3"] {
    -ms-grid-columns: (1fr)[3];
    grid-template-columns: repeat(3, 1fr);
  }
  [class*="col-"][class*="-small-3"] {
    grid-column: auto/span 3;
  }
  [class*="grid-"][class*="-small-4"] {
    -ms-grid-columns: (1fr)[4];
    grid-template-columns: repeat(4, 1fr);
  }
  [class*="col-"][class*="-small-4"] {
    grid-column: auto/span 4;
  }
  [class*="-small-all"] {
    grid-column: 1 / -1;
  }
}

.item-first {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}

.item-last {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}

.grid-offset {
  visibility: hidden;
}

.col-all {
  grid-column: 1 / -1;
}

.row-all {
  grid-row: 1 / -1;
}

/* ----------------------------- */
/* ==Media object                */
/* ----------------------------- */
/* recommended HTML : <div class="media"><img class="media-figure"><div class="media-content"></div></div> */
/* see http://codepen.io/raphaelgoetter/pen/KMWWwj */
@media (min-width: 480px) {
  .media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .media-content {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 0%;
            flex: 1 1;
  }
  .media-figure--center {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  .media--reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
}

/* ----------------------------- */
/* ==skip links                  */
/* ----------------------------- */
/* see https://www.alsacreations.com/article/lire/572-Les-liens-d-evitement.html */
/* styling skip links */
.skip-links {
  position: absolute;
}

.skip-links a {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0.5em;
  background: black;
  color: white;
  text-decoration: none;
}

.skip-links a:focus {
  position: static;
  overflow: visible;
  clip: auto;
}

/* ----------------------------- */
/* ==Tables                      */
/* ----------------------------- */
table,
.table {
  width: 100%;
  max-width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  vertical-align: top;
  margin-bottom: 2rem;
}

.table {
  display: table;
  border: 1px solid #acb3c2;
  background: transparent;
}

.table--zebra tbody tr:nth-child(odd) {
  background: #e7e9ed;
}

.table caption {
  caption-side: bottom;
  padding: 1rem;
  color: #333;
  font-style: italic;
  text-align: right;
}

.table td,
.table th {
  padding: 0.3rem 0.6rem;
  min-width: 2rem;
  vertical-align: top;
  border: 1px #acb3c2 dotted;
  text-align: left;
  cursor: default;
}

.table thead {
  color: #212529;
  background: transparent;
}

.table--auto {
  table-layout: auto;
}

/* ----------------------------- */
/* ==Forms                       */
/* ----------------------------- */
/* thanks to HTML5boilerplate and https://shoelace.style/ */
/* forms items */
form,
fieldset {
  border: none;
}

fieldset {
  padding: 2rem;
}

fieldset legend {
  padding: 0 0.5rem;
  border: 0;
  white-space: normal;
}

label {
  display: inline-block;
  cursor: pointer;
}

[type="color"],
[type="date"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="submit"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  border: 0;
  -webkit-box-shadow: 0 0 0 1px #333 inset;
          box-shadow: 0 0 0 1px #333 inset;
  color: #212529;
  vertical-align: middle;
  padding: 0.5rem 1rem;
  margin: 0;
  -webkit-transition: 0.25s;
  transition: 0.25s;
  -webkit-transition-property: background-color, color, border, -webkit-box-shadow;
  transition-property: background-color, color, border, -webkit-box-shadow;
  transition-property: box-shadow, background-color, color, border;
  transition-property: box-shadow, background-color, color, border, -webkit-box-shadow;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

[type="submit"] {
  background-color: #333;
  color: #fff;
  cursor: pointer;
}

input[readonly] {
  background-color: #e7e9ed;
}

select {
  padding-right: 2rem;
  border-radius: 0;
  background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%209.96%2011.966%20L%203.523%205.589%20C%202.464%204.627%200.495%206.842%201.505%207.771%20L%201.505%207.771%20L%208.494%2014.763%20C%209.138%2015.35%2010.655%2015.369%2011.29%2014.763%20L%2011.29%2014.763%20L%2018.49%207.771%20C%2019.557%206.752%2017.364%204.68%2016.262%205.725%20L%2016.262%205.725%20L%209.96%2011.966%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E");
  background-position: right .6rem center;
  background-repeat: no-repeat;
  background-size: 1.2rem;
}

/* hiding IE11 arrow */
select::-ms-expand {
  display: none;
}

textarea {
  min-height: 5em;
  vertical-align: top;
  resize: vertical;
  white-space: pre-wrap;
}

/* 'x' appears on right of search input when text is entered. This removes it */
[type="search"]::-webkit-search-decoration, [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-results-button, [type="search"]::-webkit-search-results-decoration {
  display: none;
}

::-webkit-input-placeholder {
  color: #777;
}

::-moz-placeholder {
  color: #777;
}

:-ms-input-placeholder {
  color: #777;
}

::-ms-input-placeholder {
  color: #777;
}

::placeholder {
  color: #777;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #777;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #777;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #777;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #777;
}

input::placeholder,
textarea::placeholder {
  color: #777;
}

progress {
  width: 100%;
  vertical-align: middle;
}

/* ----------------------------- */
/* ==Buttons                     */
/* ----------------------------- */
/* preferably use <button> for buttons !*/
/* use .btn-- or .button-- classes for variants */
.btn,
.button,
[type="button"],
button, .btn--primary,
.button--primary, .btn--success,
.button--success, .btn--info,
.button--info, .btn--warning,
.button--warning, .btn--danger,
.button--danger, .btn--inverse,
.button--inverse, .btn--ghost,
.button--ghost {
  display: inline-block;
  padding: 1rem 1.5rem;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: 0.25s;
  transition: 0.25s;
  -webkit-transition-property: background-color, color, border, -webkit-box-shadow;
  transition-property: background-color, color, border, -webkit-box-shadow;
  transition-property: box-shadow, background-color, color, border;
  transition-property: box-shadow, background-color, color, border, -webkit-box-shadow;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
  color: #212529;
  border: none;
  border-radius: 0;
  background-color: #e7e9ed;
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
}

.btn:focus,
.button:focus,
[type="button"]:focus,
button:focus {
  -webkit-tap-highlight-color: transparent;
}

.btn--primary,
.button--primary {
  background-color: #0275D8;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn--primary:active, .btn--primary:focus, .btn--primary:hover,
.button--primary:active,
.button--primary:focus,
.button--primary:hover {
  background-color: #025aa5;
}

.btn--success,
.button--success {
  background-color: #5CB85C;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn--success:active, .btn--success:focus, .btn--success:hover,
.button--success:active,
.button--success:focus,
.button--success:hover {
  background-color: #449d44;
}

.btn--info,
.button--info {
  background-color: #5BC0DE;
  color: #000;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn--info:active, .btn--info:focus, .btn--info:hover,
.button--info:active,
.button--info:focus,
.button--info:hover {
  background-color: #31b0d5;
}

.btn--warning,
.button--warning {
  background-color: #F0AD4E;
  color: #000;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn--warning:active, .btn--warning:focus, .btn--warning:hover,
.button--warning:active,
.button--warning:focus,
.button--warning:hover {
  background-color: #ec971f;
}

.btn--danger,
.button--danger {
  background-color: #D9534F;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn--danger:active, .btn--danger:focus, .btn--danger:hover,
.button--danger:active,
.button--danger:focus,
.button--danger:hover {
  background-color: #c9302c;
}

.btn--inverse,
.button--inverse {
  background-color: #333;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn--inverse:active, .btn--inverse:focus, .btn--inverse:hover,
.button--inverse:active,
.button--inverse:focus,
.button--inverse:hover {
  background-color: #1a1a1a;
}

.btn--ghost,
.button--ghost {
  background-color: transparent;
  color: #fff;
  -webkit-box-shadow: 0 0 0 1px #fff inset;
          box-shadow: 0 0 0 1px #fff inset;
}

.btn--ghost:active, .btn--ghost:focus, .btn--ghost:hover,
.button--ghost:active,
.button--ghost:focus,
.button--ghost:hover {
  background-color: rgba(0, 0, 0, 0);
}

.btn--small,
.button--small {
  padding: 0.7rem 1rem;
  font-size: .8em;
}

.btn--big,
.button--big {
  padding: 1.5rem 2rem;
  font-size: 1.4em;
}

.btn--block,
.button--block {
  width: 100% !important;
  display: block;
}

.btn--unstyled,
.button--unstyled {
  padding: 0;
  border: none;
  text-align: left;
  background: none;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.btn--unstyled:focus,
.button--unstyled:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.nav-button {
  padding: 0;
  background-color: transparent;
  outline: 0;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.nav-button > * {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: top;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  height: 2.6rem;
  width: 2.6rem;
  padding: 0;
  background-color: transparent;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#333), to(#333));
  background-image: linear-gradient(#333, #333);
  background-position: center;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: 100% 5px;
  -webkit-transition: .25s;
  transition: .25s;
  -webkit-transition-property: background, -webkit-transform;
  transition-property: background, -webkit-transform;
  transition-property: transform, background;
  transition-property: transform, background, -webkit-transform;
  will-change: transform, background;
}

.nav-button > *::before, .nav-button > *::after {
  content: "";
  height: 5px;
  background: #333;
  -webkit-transition: .25s;
  transition: .25s;
  -webkit-transition-property: top, -webkit-transform;
  transition-property: top, -webkit-transform;
  transition-property: transform, top;
  transition-property: transform, top, -webkit-transform;
  will-change: transform, top;
}

.nav-button:hover > * {
  background-color: transparent;
}

.nav-button:focus {
  outline: 0;
}

.nav-button.is-active > * {
  background-image: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.nav-button.is-active > *::before {
  -webkit-transform: translateY(50%) rotate3d(0, 0, 1, 45deg);
          transform: translateY(50%) rotate3d(0, 0, 1, 45deg);
}

.nav-button.is-active > *::after {
  -webkit-transform: translateY(-50%) rotate3d(0, 0, 1, -45deg);
          transform: translateY(-50%) rotate3d(0, 0, 1, -45deg);
}

/* ----------------------------- */
/* ==Checkbox, radio, switch     */
/* ----------------------------- */
/* use .checkbox class on input type=checkbox */
/* recommended HTML : <input type="checkbox" class="checkbox" id="c1"><label for="c1">click here</label> */
/* use .radio class on input type=radio */
/* recommended HTML : <input type="radio" class="radio" name="radio" id="r1"><label for="r1">Click here</label> */
/* use .switch class on input type=checkbox */
.checkbox {
  border-radius: 4px;
}

.switch {
  border-radius: 3em;
}

.radio {
  border-radius: 50%;
}

.switch,
.checkbox,
.radio {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  vertical-align: text-bottom;
  outline: 0;
  cursor: pointer;
}

.switch ~ label,
.checkbox ~ label,
.radio ~ label {
  cursor: pointer;
}

.switch::-ms-check,
.checkbox::-ms-check,
.radio::-ms-check {
  display: none;
}

.switch {
  width: 4rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 70%;
  -webkit-box-shadow: inset -2rem 0 0 #333, inset 0 0 0 1px #333;
          box-shadow: inset -2rem 0 0 #333, inset 0 0 0 1px #333;
  -webkit-transition: -webkit-box-shadow .15s;
  transition: -webkit-box-shadow .15s;
  transition: box-shadow .15s;
  transition: box-shadow .15s, -webkit-box-shadow .15s;
  background-color: #fff;
}

.switch::before, .switch::after {
  font-weight: bold;
  color: #fff;
}

.switch::before {
  content: "✕";
  float: right;
  margin-right: 0.66667rem;
}

.switch:checked {
  -webkit-box-shadow: inset 2rem 0 0 #5CB85C, inset 0 0 0 1px #5CB85C;
          box-shadow: inset 2rem 0 0 #5CB85C, inset 0 0 0 1px #5CB85C;
}

.switch:checked::before {
  content: "✓";
  float: left;
  margin-left: 0.66667rem;
}

.checkbox {
  width: 2rem;
  height: 2rem;
  -webkit-box-shadow: inset 0 0 0 1px #333;
          box-shadow: inset 0 0 0 1px #333;
  background-color: #fff;
  -webkit-transition: background-color .15s;
  transition: background-color .15s;
}

.checkbox:checked {
  background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220%22%20y%3D%220%22%20viewBox%3D%220%200%208%208%22%20enable-background%3D%22new%200%200%208%208%22%20xml%3Aspace%3D%22preserve%22%3E%20%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M6.4%2C1L5.7%2C1.7L2.9%2C4.5L2.1%2C3.7L1.4%2C3L0%2C4.4l0.7%2C0.7l1.5%2C1.5l0.7%2C0.7l0.7-0.7l3.5-3.5l0.7-0.7L6.4%2C1L6.4%2C1z%22%20%2F%3E%0A%3C%2Fsvg%3E");
  background-size: 60% 60%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #333;
}

.radio {
  width: 2rem;
  height: 2rem;
  background-size: 0% 0%;
  -webkit-transition: background-size .15s;
  transition: background-size .15s;
  -webkit-box-shadow: inset 0 0 0 1px #333;
          box-shadow: inset 0 0 0 1px #333;
  background-color: #fff;
}

.radio:checked {
  background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20version%3D%221.1%22%20width%3D%22100%22%20height%3D%22100%22%0AviewBox%3D%220%200%2080%2080%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Ccircle%20cx%3D%2240%22%20cy%3D%2240%22%20r%3D%2224%22%20style%3D%22fill%3A%23333333%22/%3E%3C/svg%3E");
  background-size: 90% 90%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
}

/* ----------------------------- */
/* ==Tabs                        */
/* ----------------------------- */
/* see example on https://knacss.com/styleguide.html#tabs */
/* NOTE : tabs need JavaScript to be activated */
.tabs-menu {
  border-bottom: 2px solid #e7e9ed;
}

.tabs-menu-link {
  display: block;
  margin-bottom: -2px;
  padding: 0.5rem 3rem;
  border-bottom: 4px solid transparent;
  color: #212529;
  background: transparent;
  text-decoration: none;
  border-radius: 0 0 0 0;
  -webkit-transition: .25s;
  transition: .25s;
  -webkit-transition-property: color, border, background-color;
  transition-property: color, border, background-color;
}

.tabs-menu-link.is-active {
  border-bottom-color: #333;
  color: #333;
  background: transparent;
  outline: 0;
}

.tabs-menu-link:focus {
  border-bottom-color: #333;
  color: #333;
  outline: 0;
}

@media (min-width: 576px) {
  .tabs-menu-link {
    display: inline-block;
  }
}

.tabs-content-item {
  padding-top: 1rem;
}

.tabs-content-item[aria-hidden="true"] {
  visibility: hidden;
}

.tabs-content-item[aria-hidden="false"] {
  visibility: visible;
}

/* ----------------------------- */
/* ==Arrows                      */
/* ----------------------------- */
/* see https://knacss.com/styleguide.html#arrows */
[class*="icon-arrow--"] {
  vertical-align: middle;
}

[class*="icon-arrow--"]::after {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  -webkit-mask-size: cover;
          mask-size: cover;
  background-color: #000;
  line-height: 1;
}

.icon-arrow--down::after {
  -webkit-mask-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%209.96%2011.966%20L%203.523%205.589%20C%202.464%204.627%200.495%206.842%201.505%207.771%20L%201.505%207.771%20L%208.494%2014.763%20C%209.138%2015.35%2010.655%2015.369%2011.29%2014.763%20L%2011.29%2014.763%20L%2018.49%207.771%20C%2019.557%206.752%2017.364%204.68%2016.262%205.725%20L%2016.262%205.725%20L%209.96%2011.966%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E");
          mask-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%209.96%2011.966%20L%203.523%205.589%20C%202.464%204.627%200.495%206.842%201.505%207.771%20L%201.505%207.771%20L%208.494%2014.763%20C%209.138%2015.35%2010.655%2015.369%2011.29%2014.763%20L%2011.29%2014.763%20L%2018.49%207.771%20C%2019.557%206.752%2017.364%204.68%2016.262%205.725%20L%2016.262%205.725%20L%209.96%2011.966%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E");
}

.icon-arrow--up::after {
  -webkit-mask-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%209.96%208.596%20L%203.523%2014.973%20C%202.464%2015.935%200.495%2013.72%201.505%2012.791%20L%201.505%2012.791%20L%208.494%205.799%20C%209.138%205.212%2010.655%205.193%2011.29%205.799%20L%2011.29%205.799%20L%2018.49%2012.791%20C%2019.557%2013.809%2017.364%2015.882%2016.262%2014.837%20L%2016.262%2014.837%20L%209.96%208.596%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E");
          mask-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%209.96%208.596%20L%203.523%2014.973%20C%202.464%2015.935%200.495%2013.72%201.505%2012.791%20L%201.505%2012.791%20L%208.494%205.799%20C%209.138%205.212%2010.655%205.193%2011.29%205.799%20L%2011.29%205.799%20L%2018.49%2012.791%20C%2019.557%2013.809%2017.364%2015.882%2016.262%2014.837%20L%2016.262%2014.837%20L%209.96%208.596%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E");
}

.icon-arrow--right::after {
  -webkit-mask-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%2011.685%2010.321%20L%205.308%2016.758%20C%204.346%2017.817%206.561%2019.786%207.49%2018.776%20L%207.49%2018.776%20L%2014.482%2011.787%20C%2015.069%2011.142%2015.088%209.626%2014.482%208.991%20L%2014.482%208.991%20L%207.49%201.791%20C%206.472%200.724%204.399%202.916%205.444%204.019%20L%205.444%204.019%20L%2011.685%2010.321%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E");
          mask-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%2011.685%2010.321%20L%205.308%2016.758%20C%204.346%2017.817%206.561%2019.786%207.49%2018.776%20L%207.49%2018.776%20L%2014.482%2011.787%20C%2015.069%2011.142%2015.088%209.626%2014.482%208.991%20L%2014.482%208.991%20L%207.49%201.791%20C%206.472%200.724%204.399%202.916%205.444%204.019%20L%205.444%204.019%20L%2011.685%2010.321%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E");
}

.icon-arrow--left::after {
  -webkit-mask-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%208.315%2010.321%20L%2014.692%2016.758%20C%2015.654%2017.817%2013.439%2019.786%2012.51%2018.776%20L%2012.51%2018.776%20L%205.518%2011.787%20C%204.931%2011.142%204.912%209.626%205.518%208.991%20L%205.518%208.991%20L%2012.51%201.791%20C%2013.528%200.724%2015.601%202.916%2014.556%204.019%20L%2014.556%204.019%20L%208.315%2010.321%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E");
          mask-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%208.315%2010.321%20L%2014.692%2016.758%20C%2015.654%2017.817%2013.439%2019.786%2012.51%2018.776%20L%2012.51%2018.776%20L%205.518%2011.787%20C%204.931%2011.142%204.912%209.626%205.518%208.991%20L%205.518%208.991%20L%2012.51%201.791%20C%2013.528%200.724%2015.601%202.916%2014.556%204.019%20L%2014.556%204.019%20L%208.315%2010.321%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E");
}

/* ----------------------------- */
/* ==Tags                      */
/* ----------------------------- */
/* use .tag-- classes for variants */
.tag, .tag--primary, .tag--success, .tag--info, .tag--warning, .tag--danger, .tag--inverse, .tag--ghost {
  display: inline-block;
  padding: 3px 0.5rem;
  vertical-align: baseline;
  white-space: nowrap;
  color: #212529;
  border-radius: 0;
  background-color: #e7e9ed;
  line-height: 1;
}

.tag--primary {
  background-color: #0275D8;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.tag--success {
  background-color: #5CB85C;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.tag--info {
  background-color: #5BC0DE;
  color: #000;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.tag--warning {
  background-color: #F0AD4E;
  color: #000;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.tag--danger {
  background-color: #D9534F;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.tag--inverse {
  background-color: #333;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.tag--ghost {
  background-color: transparent;
  color: #fff;
  -webkit-box-shadow: 0 0 0 1px #fff inset;
          box-shadow: 0 0 0 1px #fff inset;
}

.tag--small {
  font-size: 1.2rem;
}

.tag--big {
  font-size: 2rem;
}

.tag--block {
  width: 100% !important;
  display: block;
}

.tag.disabled, .disabled.tag--primary, .disabled.tag--success, .disabled.tag--info, .disabled.tag--warning, .disabled.tag--danger, .disabled.tag--inverse, .disabled.tag--ghost, .tag--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.tag:empty, .tag--primary:empty, .tag--success:empty, .tag--info:empty, .tag--warning:empty, .tag--danger:empty, .tag--inverse:empty, .tag--ghost:empty {
  display: none;
}

/* ----------------------------- */
/* ==Badges                      */
/* ----------------------------- */
/* use .badge-- classes for variants */
.badge, .badge--primary, .badge--success, .badge--info, .badge--warning, .badge--danger, .badge--inverse, .badge--ghost {
  display: inline-block;
  padding: 0.5rem;
  border-radius: 50%;
  color: #212529;
  background-color: #e7e9ed;
  line-height: 1;
}

.badge::before, .badge--primary::before, .badge--success::before, .badge--info::before, .badge--warning::before, .badge--danger::before, .badge--inverse::before, .badge--ghost::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
}

.badge--primary {
  background-color: #0275D8;
  color: #fff;
}

.badge--success {
  background-color: #5CB85C;
  color: #fff;
}

.badge--info {
  background-color: #5BC0DE;
  color: #000;
}

.badge--warning {
  background-color: #F0AD4E;
  color: #000;
}

.badge--danger {
  background-color: #D9534F;
  color: #fff;
}

.badge--inverse {
  background-color: #333;
  color: #fff;
}

.badge--ghost {
  background-color: transparent;
  color: #fff;
}

.badge--small {
  font-size: 1.2rem;
}

.badge--big {
  font-size: 2rem;
}

.badge.disabled, .disabled.badge--primary, .disabled.badge--success, .disabled.badge--info, .disabled.badge--warning, .disabled.badge--danger, .disabled.badge--inverse, .disabled.badge--ghost, .badge--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.badge:empty, .badge--primary:empty, .badge--success:empty, .badge--info:empty, .badge--warning:empty, .badge--danger:empty, .badge--inverse:empty, .badge--ghost:empty {
  display: none;
}

/* ----------------------------- */
/* ==Alerts                      */
/* ----------------------------- */
/* use .alert-- classes for variants */
.alert, .alert--primary, .alert--success, .alert--info, .alert--warning, .alert--danger, .alert--inverse, .alert--ghost {
  padding: 1rem 1rem;
  margin-top: 0.75em;
  margin-bottom: 0;
  color: #212529;
  border-radius: 0;
  background-color: #e7e9ed;
}

.alert a, .alert--primary a, .alert--success a, .alert--info a, .alert--warning a, .alert--danger a, .alert--inverse a, .alert--ghost a {
  color: inherit;
  text-decoration: underline;
}

.alert--primary {
  background-color: #0275D8;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.alert--success {
  background-color: #5CB85C;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.alert--info {
  background-color: #5BC0DE;
  color: #000;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.alert--warning {
  background-color: #F0AD4E;
  color: #000;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.alert--danger {
  background-color: #D9534F;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.alert--inverse {
  background-color: #333;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.alert--ghost {
  background-color: transparent;
  color: #fff;
  -webkit-box-shadow: 0 0 0 1px #fff inset;
          box-shadow: 0 0 0 1px #fff inset;
}

.alert--small {
  font-size: 1.2rem;
}

.alert--big {
  font-size: 2rem;
}

.alert--block {
  width: 100% !important;
  display: block;
}

.alert.disabled, .disabled.alert--primary, .disabled.alert--success, .disabled.alert--info, .disabled.alert--warning, .disabled.alert--danger, .disabled.alert--inverse, .disabled.alert--ghost, .alert--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.alert:empty, .alert--primary:empty, .alert--success:empty, .alert--info:empty, .alert--warning:empty, .alert--danger:empty, .alert--inverse:empty, .alert--ghost:empty {
  display: none;
}
