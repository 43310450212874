body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



table.Classement {
  font-family: "Times New Roman", Times, serif;
  border: 3px solid #4066ff;
  width: 300px;
  text-align: center;
  border-collapse: collapse;
}
table.Classement td, table.Classement th {
  border: 0px solid #FFFFFF;
  padding: 3px 2px;
}
table.Classement tbody td {
  font-size: 13px;
}
table.Classement tr:nth-child(even) {
  background: #D0E4F5;
}
table.Classement thead {
  background: #4066ff;
}
table.Classement thead th {
  font-size: 17px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
}
table.Classement tfoot td {
  font-size: 14px;
}